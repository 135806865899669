/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';
import $ from 'jquery';
import 'jquery';
import 'popper.js'
import 'bootstrap'
import 'perfect-scrollbar'
import 'owl.carousel';
import 'owl.carousel2.thumbs/dist/owl.carousel2.thumbs';
import '../js/public/parallax.min.js';
import '../js/public/lightbox-plus-jquery.min.js';
import '../js/public/custom'
//GALLERY
import '../js/public/gallery/fancybox.umd.js'
import '../js/public/gallery/lightslider.min.js'
//ENG

import Vue from 'vue/dist/vue.esm.js';

import './infrastructure/Flash';
import './infrastructure/Flashes';
import './infrastructure/Tab';
import './infrastructure/Tabs';
import './infrastructure/Modal';
import './infrastructure/DeleteLink';
import './infrastructure/DeleteModuleItem';
import './infrastructure/select2entity';
import './components/EventCheckout';
import './components/CountriesFilter';
import './components/RangeFilter';
import './components/WishlistWidget';
import './components/CartWidget';
import './components/ReviewModal';
import './components/Cart';
import './components/Autocomplete';
import './components/AddToCart';
import './components/Checkout';
import './components/FilterWidget';
import './components/ActiveFiltersWidget';
import './components/PaypalButtons';
import './components/Card';
import './components/CardForm';
import './components/SumupEmbedded';
import './components/SumupEmbeddedApplePay';
import './components/SumupSaveCard';
import './components/Settle';
import './components/MysteryBoxCheckout';
import './components/EventPaymentMethods';
import './components/MysteryBoxPaymentMethods';
import './components/CheckoutPaymentMethods';
import './components/LandingPageCategorySelector';
import './components/ChristmasGiftFinderPriceFilter';
import './components/ChristmasGiftFinderCategoryFilter';
import './components/owl.carousel2.thumbs.min';
import './components/Sommeliers';
import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"
import Rollbar from 'rollbar';

window.$ = $;


window.EventManager = new class {
    constructor() {
        this.vue = new Vue();
    }

    fire(event, data = null) {
        this.vue.$emit(event, data);
    }

    listen(event, callback) {
        this.vue.$on(event, callback);
    }
};

if($("input[type=hidden][name=ROLLBAR_ACCESS_TOKEN_CLIENT_IS_ACTIVE]").val() === "true") {
    Vue.prototype.$rollbar = new Rollbar({
        accessToken: $("input[type=hidden][name=ROLLBAR_ACCESS_TOKEN_CLIENT]").val(),
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            // Track your events to a specific version of code for better visibility into version health
            code_version: '1.0.0',
            // Add custom data to your events by adding custom key/value pairs like the one below
            environment: "public VUE JS",
        }
    });

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
    Vue.config.errorHandler = (err, vm, info) => {
        vm.$rollbar.error(err);
        throw err; // rethrow
    };
}

const app = new Vue({
    el: '#app',
    data: {
        showModal: false,
        dynamicComponents: []
    },
    mounted() {
        EventManager.listen('add-component', this.addComponent);
        EventManager.listen('remove-component', this.removeComponent);
    },
    methods: {
        addComponent(data) {
            this.dynamicComponents.push(data);
        },

        removeComponent(componentId) {
            let index = this.dynamicComponents.findIndex(item => item.id === componentId);

            this.dynamicComponents.splice(index, 1);
        },
    }
});

Vue.directive('init', {
    bind: function (el, binding, vnode) {
        vnode.context[binding.arg] = binding.value;
    }
});

window.flash = function (message, level = 'success') {
    window.EventManager.fire('flash', {message, level});
};
const application = Application.start();
const context = require.context("./controllers", false, /\.js$/);
application.load(definitionsFromContext(context));

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});


let forms = document.querySelectorAll('form');
let hasInvalidField = false;
forms.forEach(form => {
    if(form.noValidate){
        return;
    }
    form.noValidate = true;
    form.onsubmit = (e) => {
        let elms = form.querySelectorAll('input,select,textarea');

        elms.forEach(el => {
            if (hasInvalidField) {
                return;
            }

            el.classList.toggle("is-invalid", !el.checkValidity());

            if (!el.checkValidity()) {
                hasInvalidField = true;
                e.preventDefault();

                smoothScroll(el, {
                    block: "center"
                })
                    .then(() => {
                        el.reportValidity();
                        hasInvalidField = false;
                    });
            }
        });

        if (!hasInvalidField) {
            $(form).find('button[type="submit"]').prop("disabled", true);
        }
    }
})

function smoothScroll(elem, options) {

    return new Promise((resolve) => {
        if (!(elem instanceof Element)) {
            throw new TypeError('Argument 1 must be an Element');
        }
        let same = 0;
        let lastPos = null;
        const scrollOptions = Object.assign({behavior: 'smooth'}, options);
        elem.scrollIntoView(scrollOptions);
        requestAnimationFrame(check);

        function check() {
            const newPos = elem.getBoundingClientRect().top;
            if (newPos === lastPos) {
                if (same++ > 2) {
                    return resolve();
                }
            } else {
                same = 0;
                lastPos = newPos;
            }
            requestAnimationFrame(check);
        }
    });
}



